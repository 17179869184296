<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'In Hóa đơn'">
          <template v-slot:toolbar>
            <button
              type="button"
              class="btn btn-primary font-weight-bolder btn-sm"
              @click="printData"
              style="width: max-content"
            >
              <i class="fas fa-print"></i>
              In hóa đơn
            </button>
          </template>
          <template v-slot:preview>
            <!-- Header session -->
            <div id="printMe">
              <b-row>
                <div style="margin-left: 20px; margin-right: 20px; width: 100%">
                  <h5
                    class="label-color text-center font-size-print"
                    style="font-size: 18px; margin-bottom: 10px"
                  >
                    PHIẾU BÁN HÀNG
                  </h5>
                  <b-row style="margin-top: 50px">
                    <div class="col-md-8">
                      <p class="content-info font-size-print">
                        <span class="label-color font-size-print"
                          >Chi nhánh</span
                        >
                        : {{ storeAddress }}
                      </p>
                      <p class="content-info font-size-print">
                        <span class="label-color font-size-print"
                          >Tổng đài CSKH</span
                        >: 0888 999 799
                      </p>
                      <p class="content-info font-size-print">
                        <span class="label-color font-size-print"
                          >Đơn hàng</span
                        >
                        : {{ billNumber }}
                      </p>
                      <p class="content-info font-size-print">
                        <span class="label-color font-size-print">Ngày</span>
                        : {{ createdAt }}
                      </p>
                    </div>
                    <div class="col-md-4">
                      <p class="content-info font-size-print">
                        <span class="label-color font-size-print"
                          >Tên khách hàng</span
                        >
                        : {{ customerName }}
                      </p>
                      <p class="content-info font-size-print">
                        <span class="label-color font-size-print"
                          >Điện thoại</span
                        >
                        : {{ customerPhone }}
                      </p>
                      <p class="content-info font-size-print">
                        <span class="label-color font-size-print">Địa chỉ</span>
                        : {{ customerAddress }}
                      </p>
                    </div>
                  </b-row>

                  <p class="label-color font-size-print">Danh sách sản phẩm</p>

                  <table class="table table-bordered-print col-md-12 mb-10">
                    <tr>
                      <th class="label-color text-center font-size-print">
                        STT
                      </th>
                      <th class="label-color font-size-print">Tên sản phẩm</th>
                      <th class="label-color font-size-print">Bảo hành</th>
                      <th class="label-color font-size-print">SL</th>
                      <th class="label-color font-size-print">Đơn giá</th>
                      <th class="label-color font-size-print">Chiết khấu</th>
                      <th class="label-color font-size-print">Thành tiền</th>
                    </tr>
                    <tr v-for="(item, index) in listBillItem" :key="index">
                      <td class="text-center align-middle">
                        <p v-if="item.billItemType === 1">
                          {{ item.stt }}
                        </p>
                      </td>
                      <td>
                        <p
                          v-show="
                            item.type === 1 ||
                            item.type === 2 ||
                            item.type === 4
                          "
                          style="font-weight: 550; font-size: 13px"
                          class="m-0"
                          v-if="item.billItemType === 1"
                        >
                          {{ item.name }}
                        </p>

                        <p
                          v-if="item.type === 2"
                          style="font-style: italic; font-size: 12px"
                        >
                          IMEI: {{ item.imeiCode }}
                        </p>
                        <p
                          v-show="
                            item.type === 1 ||
                            item.type === 2 ||
                            item.type === 4
                          "
                          style="font-weight: 550; font-size: 13px"
                          class="ml-0"
                          v-else-if="item.billItemType === 2"
                        >
                          <i
                            style="color: rgb(24, 28, 50)"
                            class="fas fa-gift ml-4"
                          ></i>
                          {{ item.name }}
                        </p>
                        <p
                          v-show="
                            item.type === 1 ||
                            item.type === 2 ||
                            item.type === 4
                          "
                          style="font-weight: 550; font-size: 13px"
                          class="ml-0"
                          v-else-if="item.billItemType === 3"
                        >
                          <i
                            style="color: rgb(24, 28, 50)"
                            class="fas fa-tags ml-4"
                          ></i>
                          {{ item.name }}
                        </p>
                        <div
                          class="table-responsive"
                          v-show="
                            item.comboItems !== undefined &&
                            item.comboItems.length > 0
                          "
                        >
                          <table>
                            <tr>
                              <td style="border: none">
                                <p
                                  style="font-weight: 550; font-size: 13px"
                                  class="m-0"
                                >
                                  {{ item.name }}
                                </p>
                              </td>
                            </tr>
                            <tr
                              v-for="(items, i) in item.comboItems"
                              :key="i"
                              style="border-top: 1px solid gray"
                            >
                              <td
                                style="
                                  border: none;
                                  width: -webkit-fill-available;
                                "
                              >
                                <i
                                  style="color: rgb(24, 28, 50)"
                                  class="fas fa-cube"
                                ></i>
                                {{ items.productName }}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </td>
                      <td></td>
                      <td class="text-right align-middle font-size-print">
                        {{ item.quantity }}
                      </td>
                      <td class="text-right align-middle font-size-print">
                        <p
                          v-if="
                            item.billItemType === 1 || item.billItemType === 3
                          "
                        >
                          {{ formatMoney(item.price) }}
                        </p>
                      </td>
                      <td class="text-right align-middle font-size-print">
                        <p
                          v-if="
                            item.billItemType === 1 && item.discountType === 1
                          "
                        >
                          {{ formatMoney(item.discount) }}
                        </p>
                        <p
                          v-if="
                            item.billItemType === 1 &&
                            item.discountType === 2 &&
                            item.discountAmount === 0
                          "
                        >
                          {{ formatMoney(item.discount) }}
                        </p>
                        <p
                          v-if="
                            item.billItemType === 1 &&
                            item.discountType === 2 &&
                            item.discountAmount > 0
                          "
                        >
                          {{ formatMoney(item.discountAmount) }}%
                          <br />
                          <span
                            style="font-style: italic"
                            v-if="
                              item.billItemType === 1 &&
                              item.discountType === 1 &&
                              item.discountAmount > 0
                            "
                            >{{ formatMoney(item.discount) }}</span
                          >
                          <span
                            style="font-style: italic"
                            v-if="
                              item.billItemType === 1 &&
                              item.discountType === 2 &&
                              item.discountAmount > 0
                            "
                            >{{
                              formatMoney(item.discount * item.quantity)
                            }}</span
                          >
                        </p>
                      </td>
                      <td class="text-right align-middle font-size-print">
                        <p
                          v-if="
                            item.billItemType === 1 || item.billItemType === 3
                          "
                        >
                          {{ calTotalAmountItem(item) }}
                        </p>
                      </td>
                    </tr>
                  </table>

                  <p class="content-info font-size-print">
                    Điểm tích lũy còn lại: {{ customerPoint }}
                  </p>
                  <p
                    class="content-info mb-5 font-size-print"
                    v-if="pointUse > 0"
                  >
                    <span class="label-color">Tiêu điểm tích lũy:</span>
                    : {{ subtractPoint }} điểm( {{ formatMoney(pointUse) }})
                  </p>
                  <p
                    class="content-info mb-5 font-size-print"
                    v-if="depositAmount > 0"
                  >
                    <span class="label-color">Tiền đã cọc:</span>
                    : {{ formatMoney(depositAmount) }}
                  </p>
                  <p
                    class="content-info mb-5 font-size-print"
                    v-if="transferFromOrder > 0"
                  >
                    <span class="label-color">Tiền đã chuyển khoản:</span>
                    : {{ formatMoney(transferFromOrder) }}
                  </p>
                  <p
                    class="content-info mb-5 font-size-print"
                    v-if="moneyCredit > 0"
                  >
                    <span class="label-color">Tiền đã quẹt thẻ:</span>
                    : {{ formatMoney(moneyCredit) }}
                  </p>
                  <p
                    class="content-info mb-5 font-size-print"
                    v-if="moneyInstallment > 0"
                  >
                    <span class="label-color">Tiền đã trả góp:</span>
                    : {{ formatMoney(moneyInstallment) }}
                  </p>
                  <p class="content-info mb-5 font-size-print">
                    <span class="label-color">Chiết khấu trên hóa đơn</span>
                    : {{ formatMoney(discountValue) }}
                  </p>
                  <p class="content-info mb-5 font-size-print">
                    <span class="label-color">Tổng tiền phải trả</span>
                    :
                    <span class="font-size-price">{{
                      formatMoney(totalPay)
                    }}</span>
                  </p>

                  <p
                    class="content-info font-size-print"
                    style="margin-bottom: 0px"
                  >
                    <span class="label-color font-size-print"
                      >Tổng tiền bằng chữ: {{ textAmount }}</span
                    >
                  </p>

                  <p class="content-info font-size-print">
                    <span class="label-color">Ghi chú của hóa đơn </span>
                    : {{ orderNote }}
                  </p>

                  <p class="content-info font-size-warranty">
                    <span class="label-color">Ghi chú bảo hành </span>
                    <span>: {{ warrantyNote }}</span>
                  </p>
                  <p
                    class="content-info font-size-product-info"
                    v-if="listNote.length > 0"
                  >
                    <span class="label-color">Lưu ý khi sử dụng sản phẩm:</span>
                  </p>
                  <ul
                    v-if="listNote.length > 0"
                    style="
                      padding-left: 15px;
                      line-height: 20px;
                      margin-top: 5px;
                      margin-left: 5px;
                      list-style: circle;
                    "
                    class="font-size-product-info"
                  >
                    <div v-for="item in listNote" :key="item.id">
                      <li v-if="item.note">
                        <p
                          class="font-size-product-info"
                          style="margin-bottom: 0px !important"
                        >
                          {{ item.productName }}:
                        </p>
                        <div class="content-product font-size-product-info">
                          {{ item.note }}
                        </div>
                      </li>
                    </div>
                  </ul>
                  <b-container class="bv-example-row">
                    <b-row cols="12">
                      <b-col md="4" class="text-center">
                        <div>
                          <p class="text-center">
                            <span class="label-color font-size-print"
                              >BÁN HÀNG</span
                            >
                          </p>
                          <p class="font-size-name text-center">
                            {{ saleName }}
                          </p>
                        </div>
                      </b-col>
                      <b-col md="4" class="text-center">
                        <div>
                          <p class="text-center">
                            <span class="label-color font-size-print"
                              >THU NGÂN</span
                            >
                          </p>
                          <p class="font-size-name text-center">
                            {{ cashierName }}
                          </p>
                        </div>
                      </b-col>
                      <b-col md="4" class="text-center">
                        <div>
                          <p class="text-center">
                            <span class="label-color font-size-print"
                              >KHÁCH HÀNG</span
                            >
                          </p>
                          <p class="font-size-name text-center">
                            {{ customerName }}
                          </p>
                        </div>
                      </b-col>
                      <b-col></b-col>
                    </b-row>
                  </b-container>
                </div>
              </b-row>
            </div>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import Loader from '@/view/content/Loader.vue';
import ApiService from '@/core/services/api.service';
import { convertNumberToText } from './../../../utils/common';
//import moment from 'moment';

export default {
  data() {
    return {
      billNumber: '',
      storeId: '',
      date: '',
      customerName: '',
      customerPhone: '',
      listBillItem: [],
      totalAmount: 0,
      textAmount: '',
      orderNote: '',
      saleName: '',
      cashierName: '',
      loaderEnabled: false,
      listWard: [],
      listCity: [],
      listDistrict: [],
      storeAddress: '',
      storeWard: '',
      storeCity: '',
      storeDistrict: '',
      wardId: '',
      cityId: '',
      districtId: '',
      createdAt: '',
      totalQuantity: 0,
      warrantyNote: '',
      depositAmount: 0,
      transferFromOrder: 0,
      customerPoint: 0,
      totalPay: 0,
      moneyCredit: 0,
      moneyInstallment: 0,
      pointUse: 0,
      subtractPoint: 0,
      listNote: [],
      totalItemAmount: 0,
      discountValue: 0,
      customerAddress: '',
    };
  },
  created() {
    this.billNumber = this.$route.query.id;
    this.getBillById();
  },
  methods: {
    getBillById: async function () {
      await ApiService.setHeader();
      await ApiService.get(`bills/${this.billNumber}`).then((data) => {
        let {
          storeId,
          customerName,
          customerPhone,
          billItems,
          totalAmount,
          customerNote,
          saleName,
          cashierName,
          createdAt,
          totalItemAmount,
          discountValue,
          customerBillAddress,
        } = data.data.data;
        let stt = 0;
        billItems.forEach((element) => {
          if (element.isGiftTaken) {
            if (element.billItemType === 1) {
              stt++;
            }
            let productPrice = element.productPrice;
            if (element.billItemType === 3) {
              productPrice = element.repurchasePrice;
            }
            let discountItemAmount = element.discountAmount;
            if (
              element.discountType * 1 === 2 &&
              element.discountAmount <= 100
            ) {
              discountItemAmount =
                (productPrice * element.discountAmount) / 100;
            }
            let billItem = {
              stt: stt,
              id: element.productId,
              name: element.productName,
              code: element.productCode,
              price: productPrice,
              type: element.productType,
              quantity: element.quantity,
              imeiCode: element.imeiCode,
              totalPrice: element.quantity * productPrice - discountItemAmount,
              discount: discountItemAmount,
              comboItems: element.listProductInCombo,
              billItemType: element.billItemType,
              discountAmount: element.discountAmount,
              discountType: element.discountType * 1,
            };
            this.listBillItem.push(billItem);
            this.totalQuantity += element.quantity;
            const existed = this.listNote.find(
              (x) => x.productId === element.productId
            );
            if (!existed && element.productNote) {
              const noteItem = {
                productId: element.productId,
                productName: element.productName,
                note: element.productNote ? element.productNote : '',
              };
              this.listNote.push(noteItem);
            }
          }
        });
        this.storeId = storeId;
        this.getStoreById();
        this.customerName = customerName;
        this.customerPhone = customerPhone;
        this.totalAmount = totalAmount;
        this.orderNote = customerNote;
        this.saleName = saleName;
        this.cashierName = cashierName;
        this.createdAt = createdAt;
        this.warrantyNote = data.data.data.warrantyNote;
        this.depositAmount = data.data.data.depositAmount;
        this.transferFromOrder = data.data.data.transferFromOrder;
        this.customerPoint = data.data.data.customerPoint;
        this.moneyInstallment = data.data.data.moneyInstallment;
        this.moneyCredit = data.data.data.moneyCredit;
        this.subtractPoint = data.data.data.subtractPoint
          ? data.data.data.subtractPoint
          : 0;
        this.pointUse = data.data.data.pointToMoney
          ? data.data.data.pointToMoney
          : 0;
        this.totalItemAmount = totalItemAmount;
        this.discountValue = discountValue;
        this.totalPay =
          this.totalAmount -
          this.depositAmount -
          this.transferFromOrder -
          this.moneyCredit -
          this.moneyInstallment -
          this.pointUse;
        this.textAmount = convertNumberToText(this.totalPay);
        this.textAmount =
          this.textAmount.charAt(1).toUpperCase() +
          this.textAmount.slice(2).toLowerCase();
        this.customerAddress = customerBillAddress;
      });
      this.printData();
    },
    printData: async function () {
      const prtHtml = document.getElementById('printMe').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0'
      );

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}

          <style>
          .font-size-print {font-size: 20px !important;}
          .font-size-name{ font-size: 20px !important;}
           .font-size-warranty{ font-size: 26px !important;}
           .font-size-product-info{ font-size: 20px !important;}
           .font-size-price{ font-size: 30px !important; font-weight: bold !important;}
           </style>
        </head>
        <body`);
      WinPrint.document.write(`${prtHtml}

        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(function () {
        WinPrint.print();
      }, 500);

      // WinPrint.close();
    },
    getStoreById: async function () {
      ApiService.setHeader();
      ApiService.get(`stores/${this.storeId}`).then((data) => {
        this.storeAddress = data.data.data.address;
      });
    },
    wait(ms) {
      new Promise((resolve) => setTimeout(resolve, ms));
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    fetchCity: async function () {
      await ApiService.setHeader();
      await ApiService.get('city/').then((data) => {
        this.listCity = data.data.data;
        this.listCity.map((x) => {
          if (x.id === this.selectCity) {
            this.customerCity = x.name;
          }
        });
      });
    },
    getDistrict: async function () {
      await ApiService.setHeader();
      await ApiService.get(`city/${this.selectCity}/district`).then((data) => {
        this.listDistrict = data.data.data;
        this.listDistrict.map((x) => {
          if (x.id === this.selectDistrict) {
            this.customerDistrict = x.name;
          }
        });
      });
    },
    getWard: async function () {
      await ApiService.setHeader();
      await ApiService.get(`city/${this.selectDistrict}/ward`).then((data) => {
        this.listWard = data.data.data;
        this.listWard.map((x) => {
          if (x.id === this.selectWard) {
            this.customerWard = x.name;
          }
        });
      });
    },
    getOrderById: async function () {},
    getCustomerInfoById: async function () {
      await ApiService.setHeader();
      await ApiService.get(`customer/${this.customerId}`).then((response) => {
        let { fullName, city, ward, district, address, phoneNo } =
          response.data.data;
        this.customerName = fullName;
        this.selectCity = city;
        this.selectWard = ward;
        this.selectDistrict = district;
        this.address = address;
        this.customerPhone = phoneNo;
      });
    },
    getEmployeeById: async function () {
      await ApiService.query('employees' + '/' + this.saleId).then(
        (response) => {
          this.empName = response.data.data.employee.fullName;
          this.empPhone = response.data.data.employee.phoneNo;
          this.empAddress = response.data.data.employee.address;
        }
      );
    },
    calTotalAmountItem(item) {
      let result = 0;
      if (item.discountType === 2) {
        result = item.price * item.quantity - item.discount * item.quantity;
      } else {
        result = item.price * item.quantity - item.discount;
      }
      return this.formatMoney(result);
    },
  },
  components: {
    KTCodePreview,
    Loader,
  },
  computed: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Hóa đơn', route: '/bills' },
      { title: 'In hóa đơn' },
    ]);
  },
};
</script>

<style scoped>
.header-print {
  text-align: center;
  width: 100%;
}
.label-color {
  color: black;
  font-weight: 600;
}
.content-info {
  font-weight: 500;
}
.table-bordered-print th {
  border: 0.5px solid #474545;
}
.table-bordered-print td {
  border: 0.5px solid #474545;
}
.font-size-print {
  font-size: 13px;
}
.font-size-name {
  font-size: 13px;
}
.font-size-warranty {
  font-size: 13px;
}
.tdTextAlignPrice {
  text-align: right !important;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 600;
}
/* @media print {
  .col-xs-12 {
    width: 30%;
    float: left;
  }
} */
/* @media print {
  @page {
    size: 5.8in 8.3in;
  }
} */
/* @media print {
  @page {
    font-size: 130%;
  }
} */
.content-product {
  line-height: 1.375;
  white-space: pre-wrap;
  font-size: 13px;
  margin-top: 4px;
}
</style>
